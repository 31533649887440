/*eslint-disable*/
import zIndex from '@mui/material/styles/zIndex';
import { createSlice } from '@reduxjs/toolkit';
import CustomHeader from './CustomHeader';
import CustomHeader2 from './CustomHeader2';
import UnrealizedPnlCellRenderer from 'components/PositionsGrid/container/UnrealizedPnlCellRenderer';
import { strategyLists } from '../presentation/PnlGrid';

const realizedPnlGetter = (params) => {
    const formattedValue = Math.round(Number(params.value)).toLocaleString('en-IN', {
        maximumFractionDigits: 0,
        minimumFractionDigits: 0
    });
    return formattedValue;
};
const unRealizedPnlGetter = (params) => {
    const formattedValue = Math.round(Number(params.value)).toLocaleString('en-IN', {
        maximumFractionDigits: 0,
        minimumFractionDigits: 0
    });
    return formattedValue;
};




const initialState = {
    columnOptions : [
        {
            headerName: "  ",  
            headerClass: 'align-left', 
            cellStyle: { textAlign: "left" },
            width: 10,
            sort: null
        }, 
        {
            headerName: "Date", field: 'date',
            headerClass: 'align-left',
            cellStyle: { textAlign: "left" },
            width:120,
            sort: null 
        },
        {
            headerName: "Trading Symbol", field: 'tradingSymbol',
            filter: true,
            filter: "agTextColumnFilter",
            headerClass: 'align-left',
            cellClass: 'align-left',
            filter: "agSetColumnFilter",
            cellStyle:{textAlign:"left",},
            width: 200,
            sort: null   
        },
        {
            headerName: "Broker", field: 'positionCompositeKey.broker', 
            headerClass: 'align-left',
            cellStyle: { textAlign: "left" },
            width:120,
            sort: null    
        }, 
        {
            headerName: "Strategy Name", field: "strategyLists", 
            headerClass: 'align-left',
            cellStyle: { textAlign: "left" },
            width:200,
            sort: null,
            valueGetter: (params) => {
                const strategyId = params?.data?.positionCompositeKey?.strategyId; 
                // Return the corresponding strategy if it exists
                return strategyId && strategyLists ? strategyLists[strategyId] : ''
            }    
        },
        {
            headerName: "Realized",field: 'realizedLTDPnl',
            //headerComponent: CustomHeader,
            valueFormatter:realizedPnlGetter,
            //headerComponentParams: { type: 'realized', headerName: "Realized Pnl"},
            headerClass: "ag-right-aligned-header",
            cellClass: 'align-right',
            width:130,
            sort: null,
            cellRenderer: UnrealizedPnlCellRenderer,
            cellRendererParams: {
                valueFormatter: realizedPnlGetter
            },
        },
        {
            headerName: "Unrealized",field: 'unRealizedLTDPnl',
            //headerComponent: CustomHeader,
            valueFormatter:unRealizedPnlGetter,
            //headerComponentParams: { type: 'unRealized', headerName: "Unrealized Pnl" },
            headerClass: "ag-right-aligned-header",
            cellClass: 'align-right',
            width:130,
            sort: null,
            cellRenderer: UnrealizedPnlCellRenderer,
            cellRendererParams: {
                valueFormatter: unRealizedPnlGetter
            }
        }
    ]
};

const PnlTableOptionSlice = createSlice({
  name: 'pnlTableOption',
  initialState,
  reducers: {
    setTableOptionsData(state, action){
        state.columnOptions = action.payload
    },
  }
});

export const { setTableOptionsData } = PnlTableOptionSlice.actions;
export default PnlTableOptionSlice.reducer;
